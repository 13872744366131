<template>
  <PatologiesPage
    title="Il tumore allo stomaco"
    subtitle1="Tra le neoplasie dell’apparato digerente il tumore dello stomaco è il più frequente e pericoloso dopo le neoplasie del colon. La diffusione di questo tumore presenta ampie variazioni nei diversi paesi del mondo e tra le stesse regioni italiane, il che potrebbe sottintendere peculiarità di ordine ambientale e genetico nell’identificazione dei suoi fattori di rischio.

Tra i fattori ambientali esiste una correlazione tra insorgenza della neoplasia ed abitudini alimentari, in particolare il consumo di carne o pesce affumicati o conservati sotto sale, di alimenti poco proteici o grassi e ricchi di carboidrati insieme al basso consumo di frutta e verdura.

Lo studio dei fattori genetici è in continuo aggiornamento e va di pari passo con le evoluzioni scientifiche e tecnologiche. Si è visto che esistono delle correlazioni precise tra familiarità e predisposizione genetica che sarebbero alla base di alcune particolari forme giovanili.

La progressione di lesioni cosiddette precancerose non tumorali preesistenti non adeguatamente trattate e controllate, come le ulcere gastriche, potrebbe rappresentare un ulteriore fattore rischio neoplastico.

Il tipo istologico più frequente è l’adenocarcinoma. Esso comprende sottocategorie che vanno da forme più differenziate a indifferenziate e che sono direttamente correlate con il grado di malignità. Altro fattore da valutare in sede diagnostica e prognostica è il grado infiltrazione della parete e la capacità di diffusione neoplastica per via linfatica o ematica.

La sintomatologia del carcinoma gastrico è spesso vaga e aspecifica, quando si manifestano sintomi come la difficoltà digestiva, il senso di ripienezza precoce, la nausea, il vomito e la conseguente difficoltà ad alimentarsi correttamente con conseguente perdita di peso ci troviamo spesso davanti ad uno stadio avanzato di malattia."
    subtitle2="In presenza di un quadro clinico suggestivo è indispensabile una esplorazione endoscopica dell’esofago, dello stomaco e del duodeno (gastroscopia) che permette di visualizzare e localizzare eventuali neoformazioni e contestualmente effettuare prelievi bioptici della mucosa, indispensabili per la valutazione istologica e quindi la definizione assoluta della natura della diagnosi.

Una volta accertata la diagnosi tumorale è necessario valutare la sua eventuale diffusione regionale e a distanza. La TAC con il mezzo di contrasto dell’addome e del torace è l’esame migliore per ricercare eventuali sconfinamenti al di fuori dello stomaco e la presenza di disseminazione linfonodale o epatica, polmonare ecc. In alcuni casi potrebbero essere necessari approfondimenti con ulteriori indagini quali per esempio la PET-TAC (un particolare tipo di TAC che serve per capire se determinate lesioni visibili alla TAC siano tumorali), l’eco-endoscopia (un’ecografia endoscopica dello stomaco eseguita dall’interno mediante un gastroscopio speciale), più raramente la RMN soprattutto per dirimere eventuali lesioni metastatiche più difficili da inquadrare.

"
    subtitle3="Al giorno d’oggi la cura dei tumori e quindi anche di quello dello stomaco sta diventando sempre più multidisciplinare. Non interviene dunque unicamente il chirurgo ma a seconda dello stato di avanzamento della malattia è possibile proporre in maniera preventiva (prima dell’intervento) ed in maniera profilattica (dopo l’intervento) trattamenti chemioterapici e solo in casi selezionati, radioterapici. L’indicazione ad un preciso percorso viene condiviso nel nostro centro in accordo con le principali figure specialistiche che intervengono nella cura (chirurgo, oncologo, radioterapista, radiologo, gastroenterologo, anatomopatologo, medico nucleare), si ritiene infatti che non esista un unico modo di trattamento per tutti i pazienti ma che i risultati migliori si ottengano personalizzando il programma terapeutico ad ogni singolo paziente in base alle sue caratteristiche.

Il percorso più comunemente seguito dai pazienti prevede nei casi cosiddetti localmente avanzati l’esecuzione di una chemioterapia prima dell’intervento (neoadiuvante) che ha come obiettivo quello di ridurre l’estensione della malattia e quindi migliorare i risultati della chirurgia. Una volta effettuata la terapia neoadiuvante il paziente ripete alcuni esami come la gastroscopia e la TAC per valutare la risposta al trattamento effettuato e quindi viene avviato all’intervento chirurgico. In casi più rari, in cui la lesione è in uno stadio molto precoce o nei pazienti che per caratteristiche non sopporterebbero gli effetti collaterali dei chemioterapici è possibile effettuare direttamente un trattamento locale con una escissione della lesione in corso di gastroscopia (“endoscopic submucosal dissection”) oppure l’intervento chirurgico, senza necessità di effettuare alcuna chemio o radioterapia.

Nei pazienti che si presentano con una malattia metastatica (per es. fegato, polmoni, ossa) si intraprende un trattamento esclusivamente chemioterapico associato a diverse possibili tecniche per palliare i sintomi (endoprotesi, radioterapia, etc..) nell’auspicio che la malattia possa divenire operabile nel corso dei trattamenti.

 L’intervento chirurgico consiste nell’asportazione parziale o totale dello stomaco, a seconda della reale localizzazione del tumore, nell’asportazione delle principali stazioni linfonodali coinvolte e nella ricostruzione di una adeguata continuità alimentare. Normalmente viene utilizzato un segmento di intestino tenue che viene congiunto con l’esofago e che permette una rialimentazione adeguata nel giro di qualche giorno.

Nel nostro centro tali interventi possono essere eseguiti con tecnica mini-invasiva nella maggior parte dei casi riservando la chirurgia tradizionale ai casi più complessi e avanzati.

La degenza, in caso di un decorso regolare, è di circa 7 giorni.

Sulla scorta dell’esito dell’esame istologico sul pezzo operatorio si stabilirà se necessaria l’esecuzione di una chemioterapia adiuvante.

Tutti i pazienti, indipendentemente dall’esecuzione o meno della chemioterapia, devono eseguire ciclicamente per 10 anni controlli clinici e strumentali per diagnosticare precocemente una eventuale recidiva della malattia. Tali controlli prendono il nome di “follow-up”."
    cta1="Costituisce il primo momento di ricognizione e valutazione della storia clinica, dei sintomi e dello stato di salute. Il medico specialista prende visione degli esami diagnostici effettuati; per questo motivo le consigliamo di portare con sé almeno una TC del torace e dell’addome con mezzo di contrasto ed un’esofagogastroscopia recente, corredata di biopsia ed indagine istologica."
    cta2="Il medico potrebbe richiederle alcuni approfondimenti diagnostici utili alla scelta terapeutica più efficace e personalizzata. Potrebbero completare il quadro strumentale alcuni esami, fra cui lo studio radiologico delle prime vie digestive, la manometria esofagea, la pH-impedenziometria esofagea delle 24 ore e la pH-metria esofagea delle 24 ore."
    cta3="Le opportunità di trattamento possono variare da terapie mediche, mediante la sola assunzione di farmaci, a trattamenti endoscopici, fino ad intervento chirurgico con la preferenza per approcci di chirurgia mini-invasiva. La scelta migliore sarà discussa e condivisa con lei."
    cta4="Al termine della maggior parte dei trattamenti terapeutici proposti si rende utile seguire un piano di controlli diagnostici periodici, concordati con il medico specialista. Questi saranno valutati durante la visita con lo specialista e verranno suggeriti i successivi."
  />
</template>

<script>
import PatologiesPage from "../components/PatologiesPage";

export default {
  name: "Stomaco",
  components: {
    PatologiesPage,
  },
};
</script>
